$(function() {
    $('.slideshow ul').lightSlider({
        item: 1,
        loop: true,
        pause: 5000,
        speed:  800,
        mode: 'fade',
        pauseOnHover: true,
        auto: true,
        pager: false,
        controls: false
    });

    var teamSlider = $('.team-wrapper');
    if (teamSlider.length) {
        teamSlider.lightSlider({
            item: 4,
            pager: false,
            controls: false,
            loop: true,
            enableDrag: false
        });

        if (teamSlider.find('li').length <= 4) {
            $('.page-content .control').hide();
        }

        $('.page-content .prev-button').on('click', function(){
            teamSlider.goToPrevSlide();
        });

        $('.page-content .next-button').on('click', function(){
            teamSlider.goToNextSlide();
        });
    }

    var footerSlider = $('.footer-slider');
    if (footerSlider.length) {
        footerSlider.lightSlider({
            item: 1,
            pager: false,
            controls: false,
            loop: true,
            auto: true,
            pause: 4000,
            speed: 600,
            enableDrag: false,
            adaptiveHeight: true,
        });

        $('.slider-wrapper .prev-button').on('click', function(){
            footerSlider.goToPrevSlide();
        });

        $('.slider-wrapper .next-button').on('click', function(){
            footerSlider.goToNextSlide();
        });
    }

    $('.scroll-up').on('click', function () {
        $("html, body").animate({
            scrollTop: 0
        }, 1000);
    });
});
